import React, { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import { Theme, Avatar, Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import moment from "moment";
import SplitLayout from "../../layouts/SplitLayout";
import LeftPane from "../../components/LeftPane";
import DetailPane from "../../components/DetailPane";
import ClientPortalLayout from "../../layouts/ClientPortalLayout";
import SubjectData from "../../components/subject-pane/SubjectData";
import DealTeamMemberWithPopover from "../../components/deals/DealTeamMemberWithPopover";
import ClientTimeline from "../../components/deals/ClientTimeline";
import { useAlert } from "../../context/alert";
import { useParams } from "react-router-dom";

const GET_DEAL = gql`
  query getDeal($id: ID!) {
    deal(id: $id) {
      id
      name
      closeDate
      dealType {
        id
        name
        clientNoun
      }
      photoUrl
      listPrice
      listDate
      stage {
        id
        sort
      }
      address {
        street
        street2
        city
        state
        zip
      }
      contacts {
        id
        name
        avatarUrl
      }
      dealTeamMembers {
        id
        teamMember {
          id
          name
          avatarUrl
          email
          phone
          websiteUrl
        }
        role {
          id
          name
        }
      }
      stages {
        id
        name
        sort
        tasks {
          id
          name
          dueAtPreview
        }
      }
      tasks {
        id
        name
        dueAt
        dueAtPreview
        allDay
        pastDue
        completed
        completedAt
        stageTaskId
      }
      folders {
        id
        name
        dealId
        createdAt
        updatedAt
        documents {
          id
          name
          mimeType
          showInPortal
          createdAt
          uploadedBy {
            ... on Contact {
              id
              name
            }
            ... on TeamMember {
              id
              name
            }
          }
        }
      }
      documents(excludeInFolders: true) {
        id
        name
        mimeType
        createdAt
        uploadedBy {
          ... on Contact {
            id
            name
          }
          ... on TeamMember {
            id
            name
          }
        }
      }
      config {
        clientTimelineView
      }
      docusignEnvelopes {
        id
        documents {
          name
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    propertyPhotoContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.up("sm")]: {
        maxHeight: "223px",
      },
      [theme.breakpoints.down("md")]: {
        maxHeight: "150px",
      },
    },
    photoImg: {
      width: "100%",
    },
    placeholder: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.up("sm")]: {
        height: "223px",
      },
      [theme.breakpoints.down("md")]: {
        height: "150px",
      },
    },
    placeholderImg: {
      width: "80%",
      paddingTop: "22px",
    },
    separator: {
      border: "none",
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      margin: "0 30px",
    },
    realtorTeamHeader: {
      margin: "22px 25px",
    },
  })
);

const ClientView = () => {
  const classes = useStyles();
  const [deal, setDeal] = useState<any>();
  const [stages, setStages] = useState<any>();

  const params = useParams();
  const showError = useAlert();

  const onGetDealCompleted = (data: any) => {
    const deal = data.deal;
    setDeal(deal);

    // Combine each stage's stageTasks with their corresponding tasks
    const tasks = deal.tasks;
    let stages = deal.stages.map((stage: any) => {
      const stageTasks = stage.tasks.map((stageTask: any) => ({
        ...stageTask,
        task: tasks.find((task: any) => task.stageTaskId === stageTask.id),
      }));
      if (stage.id === deal.stage.id) {
        // Merge one-off tasks assigned to the deal contacts into current stage
        const fakedStageTasks = tasks
          .filter((task: any) => !task.stageTaskId)
          .map((task: any) => ({
            name: task.name,
            dueAtPreview: task.dueAt?.toLocaleString() ?? "",
            task,
          }));
        stageTasks.push(...fakedStageTasks);
      }

      return {
        ...stage,
        tasks: undefined,
        stageTasks,
      };
    });

    setStages(stages);
  };

  const { refetch, data } = useQuery(GET_DEAL, {
    variables: { id: params.id },
    onCompleted: onGetDealCompleted,
  });

  const onTaskUpdated = () => {
    refetch()
      .then((res) => {
        setDeal(res.data.deal);
      })
      .catch((err) =>
        showError(
          "There was a problem updating. Refresh the page to try again."
        )
      );
  };

  const contacts = () => deal?.contacts || [];

  const renderContact = (contact: any) => {
    const value = (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src={contact.avatarUrl}
          style={{ width: "24px", height: "24px", margin: "2px 8px 0 0" }}
        ></Avatar>
        {contact.name}
      </div>
    );

    return (
      <SubjectData
        key={contact.id}
        label={deal.dealType.clientNoun}
        value={value}
      />
    );
  };

  const renderDealTeamMember = (dealTeamMember: any): JSX.Element => {
    const teamMember = dealTeamMember.teamMember;

    if (!teamMember) {
      return <></>;
    } else {
      return (
        <DealTeamMemberWithPopover
          sx={{ marginTop: "4px" }}
          dealTeamMember={dealTeamMember}
        />
      );
    }
  };

  const shouldShowAddress = () => {
    return (
      deal.address.street?.length > 0 ||
      deal.address.city?.length > 0 ||
      deal.address.state?.length > 0 ||
      deal.address.zip?.length > 0
    );
  };

  const formatAddress = () => {
    let cityState = [];
    if (deal.address.city?.length > 0) cityState.push(deal.address.city);
    if (deal.address.state?.length > 0) cityState.push(deal.address.state);

    const line2 = [cityState.join(", "), deal.address.zip].join(" ");

    return (
      <>
        {deal.address.street}
        <br />
        {deal.address.street2 && (
          <>
            {deal.address.street2}
            <br />
          </>
        )}
        {line2}
      </>
    );
  };

  const formatValue = (value: number) => {
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return currencyFormatter.format(value);
  };

  const formatDate = (date: string) => {
    return moment(date, "YYYY-MM-DD").format("LL");
  };

  return (
    <SplitLayout parentLayout={ClientPortalLayout} loading={false}>
      <LeftPane>
        <div className={classes.propertyPhotoContainer}>
          {deal?.photoUrl ? (
            <img
              className={classes.photoImg}
              alt={deal.name}
              src={deal.photoUrl}
            />
          ) : (
            <div className={classes.placeholder}>
              <img
                src="/images/deal-placeholder-photo.png"
                className={classes.placeholderImg}
                alt="Placeholder"
              />
            </div>
          )}
        </div>

        {deal && (
          <>
            <Box style={{ margin: "24px 0" }}>
              {contacts().map((contact: any) => renderContact(contact))}

              {shouldShowAddress() && (
                <SubjectData label="Address" value={formatAddress()} />
              )}

              {deal.listPrice > 0 && (
                <SubjectData
                  label="List Price"
                  value={formatValue(deal.listPrice)}
                />
              )}

              {deal.listDate && (
                <SubjectData
                  label="List Date"
                  value={formatDate(deal.listDate)}
                />
              )}

              {deal.closeDate && (
                <SubjectData
                  label="Target Closing Date"
                  value={formatDate(deal.closeDate)}
                />
              )}
            </Box>

            <hr className={classes.separator} />

            <Box>
              <Typography variant="h4" className={classes.realtorTeamHeader}>
                Your Team
              </Typography>
              {deal.dealTeamMembers.map((dealTeamMember: any) => (
                <SubjectData
                  key={dealTeamMember.id}
                  label={dealTeamMember.role.name}
                  value={renderDealTeamMember(dealTeamMember)}
                />
              ))}
            </Box>
          </>
        )}
      </LeftPane>
      <DetailPane>
        <ClientTimeline
          refetch={refetch}
          stages={stages}
          deal={data?.deal}
          onTaskUpdated={onTaskUpdated}
        />
      </DetailPane>
    </SplitLayout>
  );
};

export default ClientView;
