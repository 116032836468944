import { gql } from "@apollo/client";

export const ENSURE_STRIPE_CHECKOUT_URL = gql`
  mutation EnsureStripeCheckoutUrl($plan: String) {
    ensureStripeCheckoutUrl(plan: $plan)
  }
`;

export const IMPORT_CONTACT = gql`
  mutation ImportContact($input: ImportContactInput!) {
    importContact(input: $input) {
      id
      firstName
      lastName
      email
    }
  }
`;

export const START_STRIPE_SESSION = gql`
  mutation {
    startStripePortalSession
  }
`;

export const START_STRIPE_SETUP_INTENT = gql`
  mutation {
    startStripeSetupIntent
  }
`;

export const SEND_INVITE = gql`
  mutation createUserInvite($input: CreateUserInviteInput!) {
    createUserInvite(input: $input)
  }
`;

export const CREATE_CONTACT = gql`
  mutation createContact($input: CreateContactInput!) {
    createContact(input: $input) {
      id
    }
  }
`;

export const GET_CONTACTS = gql`
  query GetContacts($params: ContactQueryParams!) {
    getContacts(params: $params) {
      contacts {
        id
        name
        email
        phone
        avatarUrl
        owner {
          id
          name
          avatarUrl
        }
        tags {
          id
          name
        }
      }
      page
      pageSize
      totalCount
    }
  }
`;

export const GET_CONTACTS_ID_ONLY = gql`
  query GetContacts($params: ContactQueryParams!) {
    getContacts(params: $params) {
      contacts {
        id
      }
      page
      pageSize
      totalCount
    }
  }
`;

export const FRAGMENT_TASK_FIELDS = gql`
  fragment TaskFields on Task {
    id
    name
    completed
    completedAt
    teamMemberId
    assignedToType
    contact {
      id
      name
    }
    contacts {
      id
      name
      avatarUrl
    }
    teamMember {
      id
      name
      avatarUrl
    }
    allDay
    dueAt
    dueAtPreview
    role {
      id
      name
    }
    pastDue
    notes {
      id
      body
      source
      createdAt
      author {
        id
        name
        avatarUrl
      }
      task {
        id
      }
    }
  }
`;

export const FRAGMENT_STAGE_FIELDS = gql`
  fragment StageFields on StageField {
    id
    customFieldId
    columnName
    field {
      label
      type
      settings {
        format
        formatAsCurrency
        formatAsPercentage
        includeTime
        options
        eventType
      }
    }
    sort
  }
`;

export const NEEDED_FOR_STAGE_ACTIONS = gql`
  query NeededForStageChange($stageId: ID!, $dealId: ID) {
    getStage(id: $stageId) {
      neededForActions(dealId: $dealId) {
        fields {
          ...StageFields
        }
        roles {
          id
          name
        }
      }
    }
  }
  ${FRAGMENT_STAGE_FIELDS}
`;

export const FRAGMENT_DEAL_FIELDS = gql`
  fragment DealFields on Deal {
    id
    archived
    archivedReason
    dealState
    name
    dealType {
      id
      name
      clientNoun
    }
    address {
      street
      street2
      city
      state
      zip
    }
    closingAddress {
      street
      street2
      city
      state
      zip
    }
    yearBuilt
    mlsKey
    mlsId
    mlsLink
    photoUrl
    stageId
    commission
    commissionRate
    listingDate
    listingExpirationDate
    listPrice
    listingAppointmentAt
    listingAppointmentDate
    offerDate
    offerExpirationDate
    contractAgreementDate
    possessionDate
    inspectionDate
    inspectionAt
    inspectionContingencyDate
    appraisalContingencyDate
    loanContingencyDate
    closingDate
    closingAt
    closedDate
    financingType
    earnestMoneyAmount
    earnestMoneyDueDate
    finalWalkthroughDate
    finalWalkthroughAt
    sellerConcession
    expirationDate
    currentListPrice
    salePrice
    closeDate
    closedAt
    value
    source
    createdAt
    updatedAt
  }
`;

export const FRAGMENT_DEAL_CUSTOM_FIELDS = gql`
  fragment DealCustomFields on Deal {
    customFields {
      id
      customFieldId
      type
      label
      valueText
      settings {
        format
        includeTime
        options
        eventType
      }
    }
  }
`;

export const FRAGMENT_DEAL_TEAM_MEMBERS = gql`
  fragment DealTeamMembers on Deal {
    dealTeamMembers {
      teamMember {
        id
        name
        email
        phone
        title
        company
        avatarUrl
      }
      role {
        id
        name
      }
    }
  }
`;

export const CREATE_DEAL = gql`
  mutation CreateDeal($input: CreateDealInput!) {
    createDeal(input: $input) {
      id
      name
      stage {
        id
        name
      }
      dealType {
        id
        name
        clientNoun
        dealOwnerRoleId
      }
      value
      closeDate
      dealTeamMembers {
        role {
          isBuyersAgent
          isListingAgent
        }
        teamMember {
          name
        }
      }
      updatedAt
    }
  }
`;

export const DELETE_DEAL = gql`
  mutation DeleteDeal($id: ID!) {
    deleteDeal(id: $id)
  }
`;

export const ARCHIVE_DEAL = gql`
  mutation ArchiveDeal($input: UpdateDealInput!) {
    updateDeal(input: $input) {
      id
      archived
    }
  }
`;

export const DELETE_EMAIL_TEMPLATE = gql`
  mutation DeleteEmailTemplate($id: ID!) {
    deleteEmailTemplate(id: $id)
  }
`;

export const SEND_EMAIL = gql`
  mutation SendEmail($input: SendEmailInput!) {
    sendEmail(input: $input)
  }
`;

export const SEND_TEST_EMAIL = gql`
  mutation sendTestEmailFromTemplate($input: SendEmailInput!) {
    sendTestEmailFromTemplate(input: $input)
  }
`;

export const GET_RECIPIENT_SUGGESTIONS = gql`
  query GetRecipientSuggestions($search: String) {
    getRecipientSuggestions(search: $search) {
      ... on Contact {
        id
        name
        email
        phone
        avatarUrl
      }
      ... on TeamMember {
        id
        name
        email
        phone
        avatarUrl
      }
    }
  }
`;

export const UPDATE_DEAL = gql`
  mutation UpdateDeal($input: UpdateDealInput!) {
    updateDeal(input: $input) {
      id
      name
      stageId
      closeDate
      value
    }
  }
`;

export const CREATE_EMAIL_TEMPLATE = gql`
  mutation CreateEmailTemplate($input: EmailTemplateInput) {
    createEmailTemplate(input: $input) {
      id
      attachments {
        id
        filename
      }
    }
  }
`;

export const UPDATE_EMAIL_TEMPLATE = gql`
  mutation UpdateEmailTemplate($input: EmailTemplateUpdateInput) {
    updateEmailTemplate(input: $input) {
      id
      name
      subject
      body {
        html
      }
      attachments {
        id
        filename
      }
    }
  }
`;

const FRAGMENT_EMAIL_TEMPLATE_FIELDS = gql`
  fragment emailTemplateFields on EmailTemplate {
    id
    name
    subject
    body {
      html
      plaintext
    }
    attachments {
      id
      fileName: filename
    }
  }
`;

const FRAGMENT_SMS_TEMPLATE_FIELDS = gql`
  fragment smsTemplateFields on SmsTemplate {
    id
    name
    body
  }
`;

export const GET_SMS_TEMPLATES = gql`
  query GetSmsTemplates {
    ...smsTemplateFields
  }
  ${FRAGMENT_SMS_TEMPLATE_FIELDS}
`;

export const GET_EMAIL_TEMPLATES = gql`
  query GetEmailTemplates {
    getEmailTemplates {
      ...emailTemplateFields
    }
  }
  ${FRAGMENT_EMAIL_TEMPLATE_FIELDS}
`;

export const COPY_STAGE = gql`
  mutation CopyStage($input: CopyStageInput!) {
    copyStage(input: $input) {
      id
      name
      emails {
        id
        toDealContacts
        toRoleId
        fromRoleId
        emailTemplateId
        hasSendDate
        sendDateDays
        sendDatePreposition
        sendDateTargetColumnName
        sendDateTargetCustomFieldId
        to {
          id
        }
        cc {
          id
        }
        bcc {
          id
        }
      }
      tasks {
        id
        name
        sort
      }
      fields {
        ...StageFields
      }
      sms {
        id
        sort
        smsTemplateId
        to {
          id
          roleId
          dealContacts
        }
      }
      dealType {
        id
        name
        clientNoun
        dealOwnerRole {
          id
        }
      }
      sort
      dealState
    }
  }
  ${FRAGMENT_STAGE_FIELDS}
`;

export const GET_TEMPLATES = gql`
  query GetTemplates {
    getEmailTemplates {
      ...emailTemplateFields
    }
    getSmsTemplates {
      ...smsTemplateFields
    }
  }
  ${FRAGMENT_EMAIL_TEMPLATE_FIELDS}
  ${FRAGMENT_SMS_TEMPLATE_FIELDS}
`;

export const ADD_DEAL_TEAM_MEMBER = gql`
  mutation AddDealTeamMember($input: AddDealTeamMemberInput!) {
    addDealTeamMember(input: $input) {
      role {
        id
        name
      }
      teamMember {
        id
        name
        email
        phone
        title
        company
        avatarUrl
      }
    }
  }
`;

export const REMOVE_DEAL_TEAM_MEMBER = gql`
  mutation RemoveDealTeamMember($input: AddDealTeamMemberInput!) {
    removeDealTeamMember(input: $input)
  }
`;

export const TASK_FIELDS_WITH_TEAM_MEMBER = gql`
  fragment TaskFieldsWithTeamMember on Task {
    id
    name
    completed
    inProgress
    teamMemberId
    assignedToType
    contacts {
      id
      name
      avatarUrl
    }
    teamMember {
      id
      name
      avatarUrl
    }
    allDay
    dueAt
    dueAtPreview
    role {
      id
      name
    }
    pastDue
  }
`;

export const UPDATE_TASK = gql`
  mutation UpdateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      ...TaskFieldsWithTeamMember
      notes {
        id
        body
        source
        createdAt
        author {
          id
          name
          avatarUrl
        }
        task {
          id
        }
      }
    }
  }
  ${TASK_FIELDS_WITH_TEAM_MEMBER}
`;

export const CREATE_TASK = gql`
  mutation CreateTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      ...TaskFieldsWithTeamMember
      notes {
        id
        body
        source
        createdAt
        author {
          id
          name
          avatarUrl
        }
        task {
          id
        }
      }
    }
  }
  ${TASK_FIELDS_WITH_TEAM_MEMBER}
`;

export const DELETE_TASK = gql`
  mutation DeleteTask($id: ID!) {
    deleteTask(id: $id)
  }
`;

export const DELETE_NOTE = gql`
  mutation DeleteNote($id: ID!) {
    deleteNote(id: $id)
  }
`;

export const EDIT_NOTE = gql`
  mutation EditNote($input: EditNoteInput!) {
    editNote(input: $input) {
      id
      author {
        id
        name
        avatarUrl
      }
      body
    }
  }
`;

export const CREATE_NOTE = gql`
  mutation CreateNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      id
      body
      createdAt
      author {
        id
        name
        avatarUrl
      }
      task {
        id
      }
      contact {
        name
        id
      }
    }
  }
`;

export const TEAM_MEMBER_FIELDS = gql`
  fragment TeamMemberFields on TeamMember {
    id
    name
    email
    phone
    title
    company
    avatarUrl
    preferred
    user {
      id
      email
    }
  }
`;

export const GET_TEAM_MEMBERS = gql`
  query GetTeamMembers {
    getTeamMembers {
      ...TeamMemberFields
    }
  }
  ${TEAM_MEMBER_FIELDS}
`;

export const GET_TEAM_MEMBER_SUGGESTIONS = gql`
  query GetTeamMemberSuggestions($term: String!) {
    getTeamMemberSuggestions(term: $term) {
      ...TeamMemberFields
    }
  }
  ${TEAM_MEMBER_FIELDS}
`;

export const GET_CUSTOM_FIELDS = gql`
  query GetCustomFields($tableName: String) {
    getCustomFields(tableName: $tableName) {
      id
      tableName
      label
      type
      sort
      settings {
        includeTime
        isRecurring
        options
        format
      }
    }
  }
`;

export const GET_TEAM_MEMBER_CUSTOM_FIELDS = gql`
  query GetTeamMemberCustomFields($teamMemberId: ID) {
    getTeamMember(id: $teamMemberId) {
      customFields {
        id
        customFieldId
        valueText
      }
    }
  }
`;

export const GET_EMAIL_THREAD = gql`
  query GetEmailThread($id: ID!, $contactId: ID) {
    getEmailThread(id: $id, contactId: $contactId) {
      id
      subject
      createdAt
      updatedAt
      unreadCount
      totalCount
      emails {
        id
        direction
        messageDate
        messageText
        messageHtml
        messageTextAsHtml
        from {
          ... on Contact {
            id
            name
            email
            avatarUrl
          }
          ... on TeamMember {
            id
            name
            email
            avatarUrl
          }
        }
        recipients {
          read
          to {
            ... on Contact {
              id
              name
              email
              avatarUrl
            }
            ... on TeamMember {
              id
              name
              email
              avatarUrl
            }
          }
        }
        attachments {
          id
          filename
        }
      }
    }
  }
`;

export const GET_SMS_THREADS = gql`
  query GetSmsThreads($filter: SmsFilterInput) {
    getSmsThreads(filter: $filter) {
      page
      pageSize
      totalCount
      threads {
        id
        totalCount
        firstUnreadSmsId
        lastMessageText
        updatedAt
        participants {
          id
          name
          avatarUrl
        }
      }
    }
  }
`;

export const GET_EMAIL_THREADS = gql`
  query GetThreads($filter: EmailFilterInput) {
    getEmailThreads(filter: $filter) {
      page
      pageSize
      totalCount
      threads {
        id
        subject
        createdAt
        updatedAt
        unreadCount
        totalCount
        lastEmail {
          id
          messageDate
          messagePreview
          from {
            ... on Contact {
              id
              name
              email
              avatarUrl
            }
            ... on TeamMember {
              id
              name
              email
              avatarUrl
            }
          }
          recipients {
            read
            to {
              ... on Contact {
                id
                name
                email
                avatarUrl
              }
              ... on TeamMember {
                id
                name
                email
                avatarUrl
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_DEAL_DOCUMENTS = gql`
  query GetDealDocumentTypes($dealTypeId: ID, $dealId: ID) {
    getDocumentTypes(dealTypeId: $dealTypeId, dealId: $dealId) {
      id
      name
      sort
    }
    getDocumentChecklist(dealTypeId: $dealTypeId, dealId: $dealId) {
      items {
        sort
        documentType {
          id
          name
        }
      }
    }
  }
`;

export const GET_DOCUMENT_CHECKLIST = gql`
  query getDocumentChecklist($dealTypeId: ID!) {
    getDocumentChecklist(dealTypeId: $dealTypeId) {
      items {
        sort
        documentType {
          id
          name
        }
      }
    }
  }
`;

export const GET_DOCUMENT_TYPES = gql`
  query getDocumentTypes($dealTypeId: ID!) {
    getDocumentTypes(dealTypeId: $dealTypeId) {
      id
      dealTypeId
      name
      sort
    }
  }
`;

export const UPDATE_DEAL_TYPE_FIELD_GROUP_SORT = gql`
  mutation UpdateDealTypeFieldGroupSort(
    $input: UpdateDealTypeFieldGroupSortInput!
  ) {
    updateDealTypeFieldGroupSort(input: $input)
  }
`;

export const GET_GROUPS_AND_FIELDS = gql`
  query GetGroupsAndFields($tableName: String, $dealTypeId: ID) {
    getFieldGroups(tableName: $tableName, dealTypeId: $dealTypeId) {
      id
      name
      sort
      fields {
        id
        isFavorite
        favoriteSort
        sort
        tableName
        isCustom
        label
        type
        dealTypes {
          id
          name
        }
        settings {
          includeTime
          eventType
          options
          format
          isRecurring
        }
      }
    }
  }
`;

export const FRAGMENT_FIELD = gql`
  fragment FieldsonField on Field {
    id
    tableName
    isCustom
    label
    type
    isFavorite
    favoriteSort
    dealTypes {
      id
      name
    }
    settings {
      includeTime
      eventType
      options
      format
      isRecurring
    }
    group {
      id
      name
      sort
    }
    sort
  }
`;

export const UPDATE_DEAL_TYPE_FIELD_FAVORITE = gql`
  mutation UpdateDealTypeFieldFavorite(
    $input: UpdateDealTypeFieldFavoriteInput!
  ) {
    updateDealTypeFieldFavorite(input: $input)
  }
`;

export const GET_COMBINED_FIELDS = gql`
  query GetFields($tableName: String) {
    getFieldGroups {
      id
      name
      sort
      isDefault
    }
    getFields(tableName: $tableName) {
      ...FieldsonField
    }
  }
  ${FRAGMENT_FIELD}
`;

export const GET_FIELDS = gql`
  query GetFields($tableName: String) {
    getCustomFields(tableName: $tableName) {
      id
      tableName
      type
      label
      settings {
        format
        includeTime
        eventType
        options
      }
    }
    getSystemFields(tableName: $tableName) {
      id
      tableName
      type
      label
    }
  }
`;

export const GET_FIELDS_FULL = gql`
  query GetFields($tableName: String) {
    getCustomFields(tableName: $tableName) {
      id
      type
      label
      settings {
        format
        includeTime
        eventType
        options
      }
    }
    getSystemFields(tableName: $tableName) {
      id
      type
      label
      settings {
        format
        includeTime
        eventType
        options
      }
    }
  }
`;

export const FRAGMENT_WORKFLOW_STEP_FIELDS = gql`
  fragment WorkflowStepFields on WorkflowStep {
    id
    type
    workflowId
    delayFrequency
    delayInterval
    delayPreposition
    delayStartType
    delayStartAt
    delayStartAtColumnName
    delayStartAtCustomFieldId
    delayStartAtTime
    emailTemplateId
    smsTemplateId
    taskName
    taskHasDueDate
    taskDueDateDays
    sort
    teamMemberId
    teamMember {
      id
      name
    }
    description {
      verb
      noun
      preposition
    }
  }
`;
export const FRAGMENT_WORKFLOW_FIELDS = gql`
  fragment WorkflowFields on Workflow {
    id
    name
    enabled
    activeEnrolleeCount
    steps {
      ...WorkflowStepFields
    }
    enrolledTags {
      id
      name
    }
    enrolledContacts {
      id
      contact {
        id
        name
        email
        phone
        avatarUrl
      }
      state
    }
  }
  ${FRAGMENT_WORKFLOW_STEP_FIELDS}
`;

export const GET_DEAL = gql`
  query GetDeal($id: ID!) {
    deal(id: $id) {
      dealType {
        id
        name
        clientNoun
        dealOwnerRole {
          id
          name
        }
      }
      ...DealFields
      stage {
        id
        name
      }
      contacts {
        id
        firstName
        lastName
        name
        email
        phone
        avatarUrl
        userStatus
      }
      dealTeamMembers {
        teamMember {
          id
          name
          email
          phone
          title
          company
          avatarUrl
        }
        role {
          id
          name
        }
      }
      notes {
        id
        body
        createdAt
        source
        author {
          id
          name
          avatarUrl
        }
      }
      tasks {
        ...TaskFields
      }
      config {
        clientTimelineView
      }
      customFields {
        id
        customFieldId
        type
        label
        valueText
        settings {
          format
          includeTime
          options
        }
      }
      dotloopLoopUrl
      docusignRoomUrl
      folders {
        id
        name
        documents {
          id
          name
        }
      }
      documents(excludeInFolders: true) {
        id
        name
      }
    }
  }
  ${FRAGMENT_DEAL_FIELDS}
  ${FRAGMENT_TASK_FIELDS}
`;

export const GET_DEALS = gql`
  query GetDeals(
    $state: DealState
    $closingBefore: Date
    $closingAfter: Date
    $showAll: Boolean
    $includeArchived: Boolean
    $pageSize: Int
  ) {
    getDeals(
      state: $state
      closingBefore: $closingBefore
      closingAfter: $closingAfter
      showAll: $showAll
      includeArchived: $includeArchived
      pageSize: $pageSize
    ) {
      deals {
        id
        name
        address {
          street
          street2
          city
          state
          zip
        }
        source
        value
        commission
        commissionRate
        stage {
          dealState
          dealType {
            name
          }
        }
        closedAt
        closeDate
        contacts {
          name
          firstName
          avatarUrl
          id
        }
        owner {
          id
          name
        }
        dealTeamMembers {
          role {
            name
          }
          teamMember {
            name
            id
          }
        }
      }
    }
  }
`;

export const AGGREGATE_DEALS_REPORT = gql`
  query AggregateDealReport(
    $startDate: Date
    $endDate: Date
    $teamMemberIds: [ID!]
    $state: DealState
  ) {
    aggregateDealReport(
      filters: {
        startDate: $startDate
        endDate: $endDate
        teamMemberIds: $teamMemberIds
        state: $state
      }
      fields: ["dealCount", "dealValueTotal", "dealCommissionTotal"]
      groupBy: ["closedMonth"]
    ) {
      groups {
        order
        closedMonth
        data {
          dealCount
          dealValueTotal
          dealCommissionTotal
        }
      }
    }
  }
`;

export const AGGREGATE_DEALS_REPORT_BY_TEAM_MEMBER = gql`
  query AggregateDealReport(
    $startDate: Date
    $endDate: Date
    $teamMemberIds: [ID!]
  ) {
    aggregateDealReport(
      filters: {
        startDate: $startDate
        endDate: $endDate
        teamMemberIds: $teamMemberIds
      }
      fields: ["dealCount", "dealValueTotal", "dealCommissionTotal"]
      groupBy: ["closedMonth", "teamMember"]
    ) {
      groups {
        order
        closedMonth
        groups {
          order
          teamMember {
            id
            name
          }
          data {
            dealCount
            dealValueTotal
            dealCommissionTotal
          }
        }
      }
    }
  }
`;

export const AGGREGATE_DEALS_REPORT_BY_SOURCE = gql`
  query AggregateDealReport(
    $startDate: Date
    $endDate: Date
    $teamMemberIds: [ID!]
    $state: DealState
  ) {
    aggregateDealReport(
      filters: {
        startDate: $startDate
        endDate: $endDate
        teamMemberIds: $teamMemberIds
        state: $state
      }
      fields: ["dealCount", "dealValueTotal", "dealCommissionTotal"]
      groupBy: ["source"]
    ) {
      groups {
        order
        source
        data {
          dealCount
          dealValueTotal
          dealCommissionTotal
        }
      }
    }
  }
`;

export const IMPERSONATE_USER = gql`
  mutation IMPERSONATE_USER($userId: ID!, $clientId: ID!) {
    impersonateUser(userId: $userId, clientId: $clientId) {
      token
      user {
        id
        name
        email
      }
    }
  }
`;

export const RENDER_EMAIL_PREVIEW = gql`
  query RENDER_EMAIL_REVIEW($input: EmailPreviewInput!) {
    renderEmailPreview(input: $input) {
      messageTextAsHtml
    }
  }
`;

export const SAVE_PROFILE = gql`
  mutation SaveProfile($input: ProfileInput!) {
    saveProfile(input: $input) {
      id
      email
      firstName
      lastName
      name
      avatarUrl
      timezone
      teamMember {
        id
      }
    }
  }
`;

export const GET_CALENDAR_EVENTS = gql`
  query getCalendarEvents($start: Date, $end: Date) {
    getCalendarEvents(start: $start, end: $end) {
      id
      title
      secondaryText
      allDay
      name
      start
      title
      secondaryText
      url
      eventType
    }
  }
`;

export const GET_CALENDAR_SETTINGS = gql`
  query GetCalendarPreferences {
    getCalendarPreferences {
      id
      label
      tableName
      columnName
      customFieldId
      showOnCalendar
    }
  }
`;

export const UPDATE_CALENDAR_SETTINGS = gql`
  mutation updateCalendarPreferences($input: UpdateCalendarPreferenceInput) {
    updateCalendarPreferences(input: $input)
  }
`;

export const SET_SUBSCRIBED = gql`
  mutation setSubscribed(
    $teamMemberId: ID
    $contactId: ID
    $subscribed: Boolean!
  ) {
    setAllEmailsEnabled(
      teamMemberId: $teamMemberId
      contactId: $contactId
      subscribed: $subscribed
    )
  }
`;

export const CREATE_SMS_TEMPLATE = gql`
  mutation CreateSmsTemplate($input: CreateSmsTemplateInput) {
    createSmsTemplate(input: $input) {
      id
      name
      body
    }
  }
`;

export const UPDATE_SMS_TEMPLATE = gql`
  mutation UpdateSmsTemplate($input: UpdateSmsTemplateInput) {
    updateSmsTemplate(input: $input) {
      id
      name
      body
    }
  }
`;

export const DELETE_SMS_TEMPLATE = gql`
  mutation deleteSmsTemplate($id: ID!) {
    deleteSmsTemplate(id: $id)
  }
`;

export const GET_POTENTIAL_DUPLICATES = gql`
  query GetPotentialDuplicates($params: ContactByEmailQueryParams!) {
    getPotentialDuplicates(params: $params) {
      importableContacts {
        id
        name
        email
      }
      contacts {
        id
        name
        email
        owner {
          id
          name
          avatarUrl
        }
      }
    }
  }
`;

export const GOAL_FRAGMENT = gql`
  fragment GoalFields on Goal {
    id
    year
    units
    volume
    commission
  }
`;

export const TASK_LIST_FIELDS = gql`
  fragment TaskListFields on Task {
    id
    name
    inProgress
    completed
    completedAt
    dueAt
    dueAtPreview
    allDay
    assignedToType
    stageTaskId
    pastDue
    notes {
      id
      body
      source
      createdAt
      author {
        id
        name
        avatarUrl
      }
      task {
        id
      }
    }
    contact {
      id
      name
    }
    deal {
      id
      name
      dealType {
        name
      }
      address {
        street
        street2
        city
        state
        zip
      }
      contacts {
        id
        name
      }
    }
    teamMember {
      id
      name
      avatarUrl
    }
  }
`;

export const GET_GOALS = gql`
  ${GOAL_FRAGMENT}
  query GetGoals($teamMemberId: ID, $year: Int) {
    getGoals(teamMemberId: $teamMemberId, year: $year) {
      yearly {
        ...GoalFields
      }
      monthly {
        month
        ...GoalFields
      }
    }
  }
`;
