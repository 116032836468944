import React, {
  cloneElement,
  FunctionComponent,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Box,
  TextFieldProps,
  Button,
} from "@mui/material";
import ActionIconButton from "../ActionIconButton";
import { TextField } from "@mui/material";
import TeamMemberSelect from "../TeamMemberSelect";
import { useMutation } from "@apollo/client";
import { CREATE_TASK, UPDATE_TASK } from "../../api/graphql";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { SecondaryButton, PrimaryButton } from "../buttons";
import moment from "moment";
import { Close } from "@mui/icons-material";
import { makeMoment } from "../../helpers";
import { useUser } from "../../context/user";

type TaskCreateDialogProps = {
  open?: boolean;
  deal?: any;
  task?: any;
  contact?: any;
  assignToCurrentUser?: boolean;
  onClose?: () => any;
  onTaskCreated?: (task: any) => any;
  children?: React.ReactNode;
};

const TaskCreateDialog: FunctionComponent<TaskCreateDialogProps> = ({
  open: _open,
  children,
  contact,
  deal,
  task,
  assignToCurrentUser,
  onClose,
  onTaskCreated,
}) => {
  const [open, setOpen] = useState(_open || false);
  const [name, setName] = useState("");
  const [dueAt, setDueAt] = useState<null | Date>(null);
  const { currentUser } = useUser();
  const [assigneeId, setAssigneeId] = useState<string | null>();
  const [startTime, setStartTime] = useState<null | Date>(null);
  const [note, setNote] = useState<string>("");
  const [requiresAssignment, setRequiresAssignment] = useState(false);
  const [showDueDateControls, setShowDueDateControls] = useState(
    task?.dueAtPreview ? false : true
  );

  const [createTask] = useMutation(CREATE_TASK);
  const [updateTask] = useMutation(UPDATE_TASK);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (_open === undefined) {
      setOpen(false);
    }
    if (onClose) {
      onClose();
    }
  };

  const handleAssigneeChange = (assignee: any) => {
    setAssigneeId(assignee?.id || null);
  };

  const handleCreate = () => {
    const input: any = {
      name,
      completed: false,
      allDay: startTime === null,
    };
    if (task?.id) {
      input.id = task.id;
    }
    if (deal && deal.id) {
      input.dealId = deal.id;
    }
    if (contact && contact.id) {
      input.contactId = contact.id;
    }
    if (assigneeId) {
      if (assigneeId === "deal_contacts") {
        input.assignedToType = "deal_contacts";
        input.teamMemberId = null;
      } else {
        input.assignedToType = "team_member";
        input.teamMemberId = assigneeId;
      }
    }
    if (dueAt) {
      if (startTime) {
        const hours = startTime.getHours() || 0;
        const minutes = startTime.getMinutes() || 0;
        dueAt.setHours(hours);
        dueAt.setMinutes(minutes);
      }
      input.dueAt = dueAt.toISOString();
    }
    if (!task?.id) {
      input.note = note;
    }

    const f = input.id ? updateTask : createTask;
    f({
      variables: { input },
    }).then((response) => {
      if (_open === undefined) {
        setOpen(false);
      }
      setName("");
      setDueAt(null);
      setStartTime(null);
      setAssigneeId(assignToCurrentUser ? currentUser.teamMember?.id : null);
      setNote("");

      const task = input.id
        ? response?.data?.updateTask
        : response?.data?.createTask;

      onTaskCreated && onTaskCreated(task);
    });
  };

  const handleStartDateChange = (value: Date | null | undefined) => {
    if (value) {
      setDueAt(moment(value).startOf("d").toDate());
    } else {
      setDueAt(null);
    }
  };

  const handleStartTimeChange = (value: Date | null) => {
    setStartTime(value);
    if (dueAt === null) {
      setDueAt(value);
    }
  };

  useEffect(() => {
    if (_open !== undefined) {
      setOpen(_open);
    }
  }, [_open]);

  const determineIfRequiresAssignment = useCallback(() => {
    !deal && !contact && !task?.deal && !task?.contact
      ? setRequiresAssignment(true)
      : setRequiresAssignment(false);
  }, [deal, contact, task]);

  useEffect(() => {
    if (!task) {
      if (assignToCurrentUser) {
        setAssigneeId(currentUser.teamMember?.id);
        determineIfRequiresAssignment();
      }
      return;
    }
    setName(task.name || "");

    determineIfRequiresAssignment();

    if (assignToCurrentUser) {
      setAssigneeId(currentUser.teamMember?.id);
    } else {
      if (task.assignedToType === "deal_contacts") {
        setAssigneeId("deal_contacts");
      } else {
        // Look for a team member
        const teamMemberId = task?.teamMemberId || task?.teamMember?.id || null;
        setAssigneeId(teamMemberId);
      }
    }
    if (task.dueAt) {
      let dueAt = makeMoment(task.dueAt);
      setDueAt(dueAt.toDate());
      if (task.allDay) {
        setStartTime(null);
      } else {
        setStartTime(dueAt.toDate());
      }
      setDueAt(dueAt.toDate());
    }
    setShowDueDateControls(task?.dueAtPreview ? false : true);
  }, [task, assignToCurrentUser, currentUser, determineIfRequiresAssignment]);

  return (
    <>
      {children && cloneElement(children as any, { onClick: handleOpen })}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5">
              {task?.id ? "Update Task" : "New Task"}
            </Typography>
            <ActionIconButton
              icon={Close}
              style={{ margin: "4px -5px" }}
              buttonStyle={{ width: "22px", height: "22px" }}
              onClick={handleClose}
              aria-label="close"
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ paddingTop: "10px" }}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                value={name}
                label="Give the task a name"
                onChange={(e) => setName(e.target.value)}
                variant="standard"
              />
            </Grid>

            <Grid item xs={12}>
              <TeamMemberSelect
                label="Assign to"
                value={assigneeId}
                onChange={handleAssigneeChange}
                deal={deal}
              />
            </Grid>
            {!showDueDateControls && (
              <Grid item xs={12}>
                <Box
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Typography>
                    <Typography variant="h6">Due Date</Typography>
                    {task?.dueAtPreview}
                  </Typography>
                  <Button
                    onClick={() => setShowDueDateControls(true)}
                    sx={{ textTransform: "none" }}
                  >
                    Set New Due Date
                  </Button>
                </Box>
              </Grid>
            )}
            {showDueDateControls && (
              <>
                <Grid item xs={6}>
                  <DatePicker
                    renderInput={(params: TextFieldProps) => (
                      <TextField {...params} variant="standard" fullWidth />
                    )}
                    label="Due Date"
                    value={dueAt}
                    onChange={handleStartDateChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    showToolbar
                    renderInput={(params: TextFieldProps) => (
                      <TextField {...params} variant="standard" fullWidth />
                    )}
                    label="Time (Optional)"
                    value={startTime}
                    onChange={handleStartTimeChange}
                  />
                </Grid>
              </>
            )}
            {!task?.id && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  variant="outlined"
                  rows={5}
                  value={note}
                  label="Add a Note"
                  onChange={(e) => setNote(e.target.value)}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions style={{ backgroundColor: "rgb(245, 245, 245)" }}>
          <SecondaryButton onClick={handleClose} style={{ width: "120px" }}>
            Cancel
          </SecondaryButton>
          <PrimaryButton
            onClick={handleCreate}
            style={{ width: "120px" }}
            disabled={!assigneeId && requiresAssignment}
          >
            {task?.id ? "Update Task" : "Add Task"}
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TaskCreateDialog;
